import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import LayoutWidget from "../../../components/widgets/layout";
import Seo from "../../../components/seo";
import useTranslation from "../../../hooks/useTranslation";
import RentwareArticleBooking from "../../../components/rentwareArticleBooking";

const DemoCardsViewWidget = () => {
	const { language } = useTranslation();

	const articles = [
		{
			articleId: "GXHBU7",
			view: "cards",
		},
		{
			articleId: "8B83RM",
			view: "cards",
		},
	];

	return (
		<LayoutWidget>
			<Seo
				seo={{
					metaTitle: "Demo",
					metaDescription: "It looks like you got to the demo page",
				}}
			/>

			<div className="mb-16 hidden text-center lg:block">
				<StaticImage
					src="../../../images/tesla.jpg"
					alt="Tesla red"
					layout="constrained"
					width={1440}
					className=""
					quality="80"
				/>
			</div>

			<div className="mx-auto mb-12 min-h-[365px] items-center p-4 lg:flex lg:max-w-[1440px] lg:space-x-12">
				{articles.map((article) => (
					<rtr-article-booking
						article-id={article.articleId}
						view="cards"
					></rtr-article-booking>
				))}
			</div>
		</LayoutWidget>
	);
};

export default DemoCardsViewWidget;
